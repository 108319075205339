import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';

import config from '../config';
import { AuthContext } from '../contexts/auth';
import { SetupContext } from '../contexts/setup';
import { useLazyQuery, useMutation } from '../hooks/graph';
import { formControlInvalid } from '../components/forms';
import Modal, { ModalBody } from '../components/modal';
import Splash, { SplashBody } from '../components/splash';

function Signup({ history, match, location }) {
	const { referrer } = match.params; // code: TS naming / ie stripe coupon
	const { providers } = useContext(SetupContext);
	const { isAuthenticated, /* authenticate */ } = useContext(AuthContext);
	const [ signup, { loading, called, data, error } ] = useMutation('signup');
	const { register, watch, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();

	const [ referrerState, setReferrerState ] = useState();
	const [ passwordEstimateState, setPasswordEstimateState ] = useState(0);
	const [ authPasswordEstimate ] = useMutation('authPasswordEstimate'); // zxcvbn(password).guesses_log10;
	const watchPassword = watch('password');

	const [ accountSignupPaymentSession ] = useLazyQuery('accountSignupPaymentSession', {fetchPolicy: 'network-only'});

	const searchParams = new URLSearchParams(location.search);
	const promotionCode = searchParams.get('code');
	let stripePriceId = searchParams.get('stripe_price_id'); // paid product price
	stripePriceId = stripePriceId?.startsWith('price_') ? stripePriceId : null; // ex. 'price_1Nph6iKvFpRPi5W8wmRr5zvl'

	const onPayment = async user => {
		// TODO handle errors: accountSignupPaymentSession can return empty response?!
		const { data: { accountSignupPaymentSession: session } } = await accountSignupPaymentSession({
			variables: {
				stripePriceId,
				email: user.email,
				successUrl: config.base + '/signup/payment/success',
				// reserved / cancelUrl: baseUrl: config.base + '...',
			},
		});

		try {
			/* eslint-disable new-cap */
			const stripe = window.Stripe(config.services.stripe.publicKey);
			const checkout = await stripe.initEmbeddedCheckout({clientSecret: session.clientSecret});
			checkout.mount('#checkout');
			/* eslint-enable new-cap */

			// NB. be sure to set styling:
			// https://dashboard.stripe.com/settings/branding/checkout
			// - background: f8f9fa or something to make it look "transparent"
			// - ...
		}

		catch {
			// pass
		}
	};

	// reserved
	// const onCancelPayment = () => {
	// 	setPaymentCancelState(true);
	// };

	const onSubmit = values => {
		!loading && signup({
			variables: {...values, referrer, promotionCode, stripePriceId, baseUrl: config.base},
		}).then(({ data: { signup: signupData }}) => { // ie user
			// Google Analytics: experimental
			window.gtag?.('config', config.services.gtag.id); // TODO to drop it?
			window.gtag?.('event', 'sign_up', {id: signupData.id, email: signupData.email, firstName: signupData.firstName});

			// MixPanel
			window.mixpanel?.identify(signupData.id);
			window.mixpanel?.people.set({
				utm_source: searchParams.get('utm_source') || null,
				utm_medium: searchParams.get('utm_medium') || null,
				utm_campaign: searchParams.get('utm_campaign') || null,
			});

			// FirstPromoter: see SIG-232 for details
			// https://signalstack.firstpromoter.com/setup_instructions?a=eckwyxd1
			// https://docs.firstpromoter.com/#tracking-leads-and-sign-ups
			// 'tid'  and 'ref_id' are passed implicitely for the new fpr version
			window.fpr?.('referral', {uid: signupData.id});

			// don't redirect, just output the a message concerning email address confirmation
			// obsoleted / authenticate(signup.token);
			// obsoleted / history.push(getRedirect(location.search) || '/');

			stripePriceId && onPayment(signupData);

		}).catch(err => {
			const errorMessage = (err.graphQLErrors[0] || {}).message;
			setError('submit', {message: errorMessage && errorMessage !== 'Error' ? errorMessage : 'We can\'t sign you up with these credentials'});
		});
	};

	useEffect(() => {
		watchPassword?.length > 3 ?
			authPasswordEstimate({variables: {password: watchPassword}}).then(({ data: { authPasswordEstimate: authPasswordEstimateData } }) => {
				setPasswordEstimateState(authPasswordEstimateData);
			})
			:
			setPasswordEstimateState(0);
	}, [watchPassword]);

	useEffect(() => {
		// TODO ideally referral to be verified first
		// reserved / const params = referrer?.split('-') || [];
		const [ name, credits ] = referrer?.split('-') || [];

		const provider = providers[name];
		credits && setReferrerState({provider, name, credits: parseInt(credits)});
	}, [referrer]);

	useEffect(() => {
		if (stripePriceId) {
			const script = document.createElement('script');
			script.src = 'https://js.stripe.com/v3';
			script.async = false;
			document.body.appendChild(script);

			// reserved
			// script.addEventListener('load', async () => {
			// 	// pass
			// });
		}
	}, [stripePriceId]);

	useEffect(() => {
		if (isAuthenticated) {
			history.replace('/'); // you have to leave
			return;
		}

		window.gtag?.('config', config.services.gtag.id); // TODO to drop it?
		window.gtag?.('event', 'begin_checkout'); // Juan, Jun23: "I understand it’s not a purchase, but we do have the same structure in TS GA4"
	}, [true]);

	return (
		<Splash>
			<SplashBody>
				<h3 className="text-center mb-3">Sign up to SignalStack</h3>

				{
					called && data && !error ? (
						<div>
							{
								stripePriceId ? (
									<div id="checkout"></div>
								) : (
									<div className="alert alert-success" role="alert">
										Thanks for the registration.<br/>
										Please, check for the confirmation email to continue.
									</div>
								)
							}
						</div>
					) : (
						<form className="d-grid" onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
							{
								// experimental / temporal
								stripePriceId ? false : (
									<div className="alert alert-secondary mt-1" role="alert">
										{/* TODO update the condition on "subscriptions" release */}
										{/* <span>You will get 25 free signals{referrerState?.credits ? ` and ${referrerState.credits} bonus signals${referrerState.provider ? ' from ' + referrerState.provider.title : ''}` : ''}.</span> */}
										<span>You will get {config.credits.default} free signals / month{referrerState?.credits ? ` and ${referrerState.credits} bonus signals${referrerState.provider ? ' from ' + referrerState.provider.title : ''}` : ''}.</span>
										{/* <span>{ promotionCode ? `With ${promotionCodeState.coupon.percent_off}% discount.` : '' }</span> */}
									</div>
								)
							}

							<div className="mt-3">
								<label htmlFor="firstName">First name</label>
								<input {...register('firstName')} id="firstName" className={formControlInvalid(errors.firstName, 'form-control')} />
							</div>

							<div className="mt-3">
								<label htmlFor="lastName">Last name</label>
								<input {...register('lastName')} id="lastName" className={formControlInvalid(errors.lastName, 'form-control')} />
							</div>

							<div className="mt-3">
								<label htmlFor="email">Email</label>
								<input type="email" {...register('email', {required: 'Required', pattern: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i})} id="email" className={formControlInvalid(errors.email, 'form-control')} />
								{errors.email?.type === 'required' && <span className="invalid-feedback">{errors.email.message}</span>}
								{errors.email?.type === 'pattern' && <span className="invalid-feedback">Invalid email</span>}
							</div>

							<div className="mt-3">
								<label htmlFor="password">Password</label>
								<div className="input-group">
									<input {...register('password', {validate: () => true || passwordEstimateState > 10})} type="password" id="password" className={formControlInvalid(errors.password, 'form-control')} />
									<a onClick={event => { event.preventDefault(); event.target.closest('.input-group')?.querySelector('input')?.setAttribute('type', 'text'); }} role="button" className="input-group-text"><img src="/static/images/icons/eye.svg" /></a>
								</div>
								{errors.password && <span className="invalid-feedback">Password too weak.</span>}
							</div>

							<div className="mt-3">
								<label htmlFor="password">Password confirmation</label>
								<div className="input-group">
									<input {...register('password2', {validate: value => !watchPassword || value === watchPassword})} type="password" id="password2" className={formControlInvalid(errors.password2, 'form-control')} />
									<a onClick={event => { event.preventDefault(); event.target.closest('.input-group')?.querySelector('input')?.setAttribute('type', 'text'); }} role="button" className="input-group-text"><img src="/static/images/icons/eye.svg" /></a>
								</div>
								{!errors.password && errors.password2 && <span className="invalid-feedback">Password confirmation doesn&apos;t match password.</span>}

								<div className="progress progress-password mt-3" style={{opacity: watchPassword ? 1 : 0}}>
									<div className={'progress-bar ' + (passwordEstimateState > 10 ? 'bg-success' : 'bg-danger')} role="progressbar" style={{width: `${passwordEstimateState * 10}%`}} aria-valuemin="0" aria-valuemax="10"></div>
								</div>
							</div>

							<div className="form-check mt-3">
								<input {...register('tos', {required: 'Required'})} type="checkbox" value="" id="tos" className="form-check-input" />
								<label className="form-check-label" htmlFor="tos">I have read and agree to SignalStack&apos;s <Link to="/terms-of-service" title="Terms of Service" className="" target="_blank">Terms of Service</Link>.</label>
							</div>

							<button type="submit" className="btn btn-primary mt-4">Continue</button>

							{
								called && error && errors.submit && (
									<div className="alert alert-danger mt-3" role="alert">
										{errors.submit.message}
									</div>
								)
							}
						</form>
					)
				}
			</SplashBody>

			<div className="mt-4 text-center text-secondary">
				Have an account? <Link to={{pathname: '/signin', state: location.state}} title="Signin" className="">Sign&nbsp;in</Link>
			</div>

			{
				false && (
					<Modal show={true} className="modal-lg">
						<ModalBody>
							<div id="checkout"></div>
						</ModalBody>
					</Modal>
				)
			}
		</Splash>
	);
}

export default withRouter(Signup);
