import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { graph, useLazyQuery } from '../hooks/graph';

export const AuthContext = React.createContext();

// move to config
const GROUP_ADMIN = 1;
const GROUP_CUSTOMER = 4;

const isAuthorized = (context, auth = []) => {
	auth = Array.isArray(auth) ? auth : [auth];
	return auth.reduce((prev, curr) => prev || context['is' + curr], !auth.length);
};

export const RouteAuth = ({ path, children, auth, location }) => {
	const context = useContext(AuthContext);

	if (context.isAuthenticated) {
		return isAuthorized(context, auth) ? (
			<Route path={path} render={() => children} />
		) : (
			<Redirect to="/me" />
		);
	}

	return <Redirect to={{pathname: '/signin', state: {from: location}}} />;
};

export const AuthProvider = props => {
	const [ userRetrieve ] = useLazyQuery('user', {
		fetchPolicy: 'network-only',
		onCompleted: ({ user }) => {
			setUser(user);

			// mixpanel
			window.mixpanel?.identify(user.id);
			window.mixpanel?.track('Session Started', {distinct_id: user.id});

			// clarity
			window.clarity?.('set', 'email', user.email);
			window.clarity?.('set', 'firstName', user.firstName);

			// reamaze / experimental
			window._support = { 'ui': {}, 'user': {id: user.id, email: user.email, name: user.firstName, authkey: user.reamazeAuthKey}};
			require('../static/scripts/reamaze.js');
		},
		onError: () => {
			// outdated access token or something else
			deauthenticate();
		},
	});

	// nb. it's assumed that "graphql" and "oauth" tokens are interchangable
	const token = localStorage.getItem('token') || new URLSearchParams(props.history?.location.search).get('token'); // to config?
	const proxiedUserEmail = sessionStorage.getItem('proxiedUserEmail'); // to config?
	const [ state, setState ] = useState({
		isLoaded: !token,
		isAuthenticated: !!token,
		token: token || undefined,
		proxiedUser: proxiedUserEmail || undefined,
		// isCustomer: ...
	});

	const setUser = user => {
		setState({...state, user,
			isLoaded: true,
			isAdmin: !!(user.group & GROUP_ADMIN),
			isCustomer: !!(user.group & GROUP_CUSTOMER),
		});
	};

	const setProxiedUser = email => {
		setState({...state, proxiedUser: email});
		graph.client().clearStore();

		email ?
			sessionStorage.setItem('proxiedUserEmail', email)
			:
			sessionStorage.removeItem('proxiedUserEmail');
	};

	const authenticate = newToken => {
		localStorage.setItem('token', newToken); // to config?
		setState({...state,
			isLoaded: !!state.user,
			isAuthenticated: true,
			token: newToken,
		});
	};

	const deauthenticate = () => {
		localStorage.removeItem('token'); // to config?
		sessionStorage.removeItem('proxiedUserEmail'); // experimental // to config?
		graph.client().clearStore();

		setState({...state,
			isLoaded: true,
			isAuthenticated: false,
			token: undefined,
		});
	};

	// refresh user data on auth status change
	useEffect(() => {
		state.isAuthenticated ? userRetrieve() : setState({...state, user: null});
	}, [state.isAuthenticated, userRetrieve]);

	// set extra error handler
	graph.onUnauthenticatedError = () => {
		const isProxiedUser = !!sessionStorage.getItem('proxiedUserEmail');
		isProxiedUser ? setProxiedUser(undefined) : deauthenticate();
	};

	return state.isLoaded ? (
		<AuthContext.Provider value={{
			...state,
			authenticate,
			deauthenticate,
			setUser,
			setProxiedUser,
		}}>
			{props.children}
		</AuthContext.Provider>
	) : false;

	// reserved: for debugging
	// ) : (
	// 	<div className="text-white">
	// 		<p>isLoaded: {state.isLoaded?.toString()}</p>
	// 		<p>isAuthenticated: {state.isAuthenticated?.toString()}</p>
	// 		<p>isAdmin: {state.isAdmin?.toString()}</p>
	// 		<p>isCustomer: {state.isCustomer?.toString()}</p>
	// 		<p>token: {!!state.token?.toString()}</p>
	// 		<p>user: {JSON.stringify(state.user)}</p>
	// 	</div>
	// );
};

export default AuthProvider;
