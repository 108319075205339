import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import QRious from 'qrious';

import { useQuery, useMutation } from '../hooks/graph';
import { formControlInvalid } from '../components/forms';
import Splash, { SplashBody } from '../components/splash';

const QR_WIDTH_HEIGHT = '11em';

function SignupPasscode({ history, location }) {
	const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
	const { data } = useQuery('userPasscode');
	const [ userPasscodeUpdate, { called, error } ] = useMutation('userPasscodeUpdate');
	const qriousRef = useRef();

	const onSubmit = values => {
		userPasscodeUpdate({
			variables: {secret: data?.userPasscode?.secret, ...values},
		}).then(() => {
			history.push(location.state?.from || '/me');
		}).catch(() => {
			setError('submit', {message: 'The verification code is not valid'});
		});
	};

	useEffect(() => {
		data && new QRious({
			element: qriousRef.current,
			value: data.userPasscode.uri,
		});
	}, [data]);

	return (
		<Splash>
			<SplashBody>
				<h3 className="text-center mb-3">Sign up to SignalStack</h3>

				<form className="d-grid" onSubmit={handleSubmit(onSubmit)} onFocus={() => errors.submit && clearErrors('submit')}>
					<div>
						Enable two-factor authentication by scanning the barcode with your authentication app and enter the verification code below.
					</div>

					<div className="mt-3">
						<div className="my-1" style={{height: QR_WIDTH_HEIGHT}}>{/* keep it to preserve post-loading resize */}
							{
								data && (
									// obsoleted <img id="qr" src={data.userPasscode.qr} className="d-block mx-auto" style={{width: QR_WIDTH_HEIGHT, height: QR_WIDTH_HEIGHT}} />
									<canvas ref={qriousRef} className="d-block mx-auto" style={{width: QR_WIDTH_HEIGHT, height: QR_WIDTH_HEIGHT}} />
								)
							}
						</div>
					</div>

					<div className="mt-3">
						<label className="form-label" htmlFor="passcode">Two-factor authentication code</label>
						<input type="text" {...register('passcode', {required: 'Required', minLength: 6, maxLength: 6})} id="passcode" className={formControlInvalid(errors?.passcode, 'form-control')} />
						{errors?.passcode && <span className="invalid-feedback">{errors.passcode.message}</span>}
					</div>

					<button type="submit" className="btn btn-primary mt-4">Enable MFA</button>

					{
						called && error && errors.submit && (
							<div className="alert alert-danger mt-3" role="alert">
								{errors.submit.message}
							</div>
						)
					}
				</form>
			</SplashBody>

			<div className="mt-4 text-center">
				Don&apos;t care? <Link to={location.state?.from || '/me'} title="Skip it" className="">Skip it</Link>
			</div>
		</Splash>
	);
}

export default withRouter(SignupPasscode);
